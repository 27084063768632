import React, { useEffect, useState } from "react";

import { BsArrowUpSquareFill } from "react-icons/bs";
import { Outlet } from "react-router-dom";
import { Link, animateScroll } from "react-scroll";
import AppFooter from "../AppFooter";
import AppHeader from "../AppHeader";
import { IoIosCloseCircleOutline } from "react-icons/io";
import CommunityModal from "./component/CommunityModal";
import { useLocation } from "react-router-dom";
import AnalyticsTracker from "../../../AnalyticsTracker";

const AppLandingPageLayout = () => {
  const location=useLocation();
  const [scrollTop, setScrollTop] = useState(false);
  const [isBanner, setIsBanner] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 500) {
      setScrollTop(true);
    } else {
      setScrollTop(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(()=>{
    scrollToTop();
  },[location?.pathname])

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  return (
    <div className="bg-black/90 w-full h-full relative">
      <AnalyticsTracker/>
      <CommunityModal/>
      {isBanner && (
        <div className="text-center bg-primary-color p-1 sticky top-0 z-[9999] flex py-2">
          <div className="flex flex-col sm:flex-row items-center sm:justify-center gap-1 sm:gap-4 mx-auto pr-3 sm:pr-2">

            <p className="flex flex-row items-center justify-center gap-1 font-display font-bold text-xs md:text-base">
              <a
                href="https://www.producthunt.com/posts/autoflow-studio"
                target="_blank"
                rel="noopener noreferrer"
                className="text-slate-800 animate-pulse pl-1 text-base md:text-lg underline decoration-1 underline-offset-2 sm:whitespace-nowrap"
              >
                Support us on Product Hunt and Get 6 months free on yearly plans!
              </a>
            </p>
            
            <a
              href="https://www.producthunt.com/posts/autoflow-studio?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-autoflow&#0045;studio"
              target="_blank"
              rel="noopener noreferrer"
              className="ml-5 sm:ml-0 w-36 sm:w-40 md:w-52"
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=626609&theme=light"
                alt="AutoFlow&#0032;Studio - Ship&#0032;faster&#0032;and&#0032;test&#0032;smarter&#0032;with&#0032;simplified&#0032;AI&#0045;powered&#0032;QA | Product Hunt"
                className="object-contain"
              />
            </a>
          </div>
          <button
            className="w-fit h-fit my-auto top-0 bottom-0 absolute right-2"
            onClick={() => setIsBanner(false)}
          >
            <IoIosCloseCircleOutline className="md:text-2xl" />
          </button>
        </div>
      )}
      <AppHeader />
      <div>
        <Outlet />
      </div>
      <AppFooter />
      {scrollTop && (
        <Link
          smooth={true}
          duration={1000}
          className="fixed bottom-32 right-5 sm:bottom-32 sm:right-5"
        >
          <button
            onClick={scrollToTop}
            className="btn btn-sm btn-ghost px-1 drop-shadow-2xl opacity-65"
          >
            <BsArrowUpSquareFill className="text-2xl sm:text-3xl text-warning shadow-inner" />
          </button>
        </Link>
      )}
    </div>
  );
};

export default AppLandingPageLayout;
