import {useEffect} from 'react';
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";


ReactGA.initialize('G-8T57Y726QD'); // Replace with your Tracking ID

function trackPageView(location) {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
}

const AnalyticsTracker = () => {
    const location = useLocation();

    useEffect(() => {
        trackPageView(location);
    }, [location]);

    return null;
};

export default AnalyticsTracker;